class Modals {
	constructor(_element) {
		this.element = _element
	}
}

$('#deleteUserModal').on('show.bs.modal', function (e) {
	const button = e.relatedTarget
	const userId = button.getAttribute('data-userid')

	const modalButton = $(this).find('.btn-delete-user')

	modalButton.attr('href', `logic/delete_user.php?user_id=${userId}`)
})

$('#resetPasswordModal').on('show.bs.modal', function (e) {
	const button = e.relatedTarget
	const userId = button.getAttribute('data-userid')

	const modalButton = $(this).find('.btn-reset-password')

	modalButton.attr('href', `logic/reset_password.php?user_id=${userId}`)
})

export default Modals
