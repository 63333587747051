class KanbanFilter {
	constructor(_element) {
		this.element = _element
	}
}
$('.js_kanbanFilter').on('click', function() {
	let userId = $(this).data("filter")
	if($('.js_filterUserItem').length > 0) {
		$('.js_filterUserItem').each(function() {
			let userCard = $(this).find('.js_userFilter').data("userfilter")
			if(!userCard && !$('#customSwitch1')[0].checked) {
				$(this).fadeOut(300)
			} else {
				$(this).fadeIn(300)
			}
		})
	}
})

export default KanbanFilter
