class FormValidation {
	constructor(_element) {
		this.element = _element
	}
}

(function () {
	'use strict'
	window.addEventListener('load', () => {
		const forms = document.getElementsByClassName('needs-validation')

		Array.prototype.filter.call(forms, form => {
			form.addEventListener('submit', event => {
				if (form.checkValidity() === false) {
					event.preventDefault()
					event.stopPropagation()
				}

				form.classList.add('was-validated')
			}, false)
		})
	}, false)
})()

export default FormValidation
