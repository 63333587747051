class BoardTable {
	constructor(_element) {
		this.element = _element
	}
}

// select all
$('.selectAll').on('click', (e) => {
	if ($(e.currentTarget).hasClass('selected')) {
		$('#boardTable').find('.form-check-input').prop('checked', false)
		$(e.currentTarget).removeClass('selected')
	} else {
		$('#boardTable').find('.form-check-input').prop('checked', true)
		$(e.currentTarget).addClass('selected')
	}
})

// select checkbox by row click
$('#boardTable').on('click', 'tr', (e) => {
	if ($(e.currentTarget).find('.form-check-input').is(':checked')) {
		$(e.currentTarget).find('.form-check-input').prop('checked', false)
	} else {
		$(e.currentTarget).find('.form-check-input').prop('checked', true)
	}
})

$('#boardTable').on('click', 'input', (e) => {
	e.stopPropagation()
})

export default BoardTable
